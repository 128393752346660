import React from 'react'
import { Redirect, Route, Switch } from "react-router-dom";
import UserVerificationPage from '../views/UserverificationPage'
import PaymentPage from '../views/PaymentPage'
import Paymentcomplete from '../views/PaymentCompletePage';

export default function RootLayout() {
  return (
    <Switch>
      <Route path="/verify-user" component={UserVerificationPage} />
      <Route path="/pay-premium" component={PaymentPage} />
      <Route path="/payment-complete" component={Paymentcomplete} />
      <Redirect to="/verify-user" />
    </Switch>
  );
}
