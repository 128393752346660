import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import bg from "../assets/background.png";
import logo from "../assets/logo.svg";
import { Center, Image, Heading } from "native-base";

const PaymentcompletePage = () => {
  const history = useHistory();

  React.useState(() => {

    const timer = setTimeout(() => {
      history.replace("/");
    }, 7000);

    return () => clearTimeout(timer);
    
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "4vh",
        paddingBottom: "4vh",
        height: "100vh",
      }}
    >
      <BackgroundImage />

      <Center
        w={["95%", "90%", "80%", "60%"]}
        bg="white.500"
        rounded={5}
        shadow={5}
        px={5}
        py={10}
      >
        <Image
          source={logo}
          alt="logo"
          accessibilityLabel="logo"
          w="40vh"
          h="10vh"
          resizeMode="contain"
        />

        <Heading mt={5}>Payment Process Completed</Heading>
        <Heading mt={2}>Thank You!</Heading>
      </Center>
    </div>
  );
};

const BackgroundImage = styled.div`
  background: url("${bg}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -100;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export default PaymentcompletePage;
